@import "../mixins.scss";
.Contact {
   h1 {
      font-family: 'Indie Flower', cursive;
      color: #13BF6D;
      font-weight: bold;
      text-align: center;
      &::after {
         content: '';
         display: block;
         width: 250px;
         height: 2px;
         background: #13BF6D;
         border-radius: 50%;
         margin: 0 auto 80px;
      }
   }
   .left {
      i {
         width: 50px;
         height: 50px;
         border-radius: 50%;
         line-height: 50px;
         background: #13BF6D;
         color: #fff;
         text-align: center;
         font-size: 20px;
         margin-right: 10px;
      }
      p {
         color: #13BF6D;
         font-weight: bold;
         font-size: 18px;
         margin-bottom: 30px;
      }
   }
   .right {
      input, textarea {
         display: block;
         margin-bottom: 15px;
         width: 100%;
         border-radius: 30px;
         border: 5px solid #13BF6D;
         border-top: 0;
         border-bottom: 0;
         color: #13BF6D;
         resize: none;
         &::placeholder {
            color: #13BF6D;
         }
      }
      button {
         display: block;
         margin-bottom: 15px;
         width: 100%;
         border-radius: 30px;
         border: 5px solid #0a9451;
         border-top: 0;
         border-bottom: 0;
         background: #13BF6D;
         color: #fff;
      }
   }
   .map {
      padding: 80px 0;
      iframe {
         height: 450px;
         width: 100%;
         border: 10px double #13BF6D;
      }
   }
}