/*  { General Rules & Styles For Elements }   */
$mainColor: #341f97;
$h1: 48px;
$h2: 38px;
$h3: 30px;
$h4: 24px;
$h5: 18px;
$h6: 15px;
$p: 15px;

/*  { General Rules & Styles For Elements }   */
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*:not(img):not(.img):not(.images) {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

html {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 10px 0;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 38px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 15px;
}

p {
  color: #676767;
  line-height: 25px;
  font-size: 15px;
}

span {
  display: inline-block;
}

button {
  background: #002147;
  padding: 10px 30px;
  color: #fff;
  border: 1px solid #999;
  outline: none;
  border-radius: 25px;
  font-size: 15px;
  cursor: pointer;
  -webkit-box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}

button:focus {
  outline: none;
}

button:hover {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}

input,
textarea {
  background: #f8f8f8;
  border-radius: 15px;
  border: 2px solid #f2f2f2;
  padding: 12px 20px;
  outline: none;
  font-size: 15px;
  resize: none;
}

input:focus,
textarea:focus {
  border-color: #002147;
}

table {
  max-width: 100%;
}

table tr th,
table tr td {
  font-size: 17px;
  border: 1px solid #ccc;
  padding: 7px 25px;
}

img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  vertical-align: middle;
}

.thumb-sm {
  width: 50px;
  height: 50px;
}

.thumb-md {
  width: 90px;
  height: 90px;
}

.thumb-lg {
  width: 140px;
  height: 140px;
}

.circle {
  border-radius: 50%;
}

.social-icons i {
  display: inline-block;
  font-size: 18px;
  color: #fff;
  padding: 10px;
  border-radius: 50%;
  background: #002147;
  margin: 0 4px;
  cursor: pointer;
}

.social-icons i:hover {
  -webkit-transform: translateY(3px);
  transform: translateY(3px);
}

.social-icons i:first-child {
  background: #2aa3f0;
  /* Twitter */
}

.social-icons i:nth-child(2) {
  background: #3b5998;
  /* Facebook */
}

.social-icons i:nth-child(3) {
  background: #fc0d1c;
  /* YouTube */
}

.social-icons i:nth-child(4) {
  background: #fb416b;
  /* Instagram */
}

.social-icons i:nth-child(5) {
  background: #bd081c;
  /* Pinterest */
}

/***** Adjusting Font Sizes For All Screens *****/
@media (max-width: 992px) {
  h1 {
    font-size: 40.8px;
  }

  h2 {
    font-size: 32.3px;
  }

  h3 {
    font-size: 25.5px;
  }

  h4 {
    font-size: 20.4px;
  }

  h5 {
    font-size: 15.3px;
  }

  h6 {
    font-size: 12.75px;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 28.5px;
  }

  h3 {
    font-size: 22.5px;
  }

  h4 {
    font-size: 18px;
  }

  h5 {
    font-size: 13.5px;
  }

  h6 {
    font-size: 11.25px;
  }

  p {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  button {
    padding: 8px 20px;
  }

  input,
  textarea {
    padding: 10px 16px;
    width: 100%;
  }

  button,
  input,
  textarea {
    font-size: 13px;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Indie+Flower&family=Montserrat&family=Notable&family=Pangolin&display=swap");

*:not(i) {
  font-family: "Montserrat", sans-serif;
  color: $mainColor;
}

::-moz-selection {
  background: #13bf6d;
  color: #fff;
}

::selection {
  background: #13bf6d;
  color: #fff;
}
html,
body {
  overflow-x: hidden;
  background: url("https://www.toptal.com/designers/subtlepatterns/patterns/so-white.png");
}

@keyframes ldio-kzcuxtvqtwp {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ldio-kzcuxtvqtwp > div > div {
  transform-origin: 100px 100px;
  animation: ldio-kzcuxtvqtwp 3.0303030303030303s linear infinite;
  opacity: 0.8;
}
.ldio-kzcuxtvqtwp > div > div > div {
  position: absolute;
  left: 30px;
  top: 30px;
  width: 70px;
  height: 70px;
  border-radius: 70px 0 0 0;
  transform-origin: 100px 100px;
}
.ldio-kzcuxtvqtwp > div div:nth-child(1) {
  animation-duration: 0.7575757575757576s;
}
.ldio-kzcuxtvqtwp > div div:nth-child(1) > div {
  background: #13bf6d;
  transform: rotate(0deg);
}
.ldio-kzcuxtvqtwp > div div:nth-child(2) {
  animation-duration: 1.0101010101010102s;
}
.ldio-kzcuxtvqtwp > div div:nth-child(2) > div {
  background: #0a9451;
  transform: rotate(0deg);
}
.ldio-kzcuxtvqtwp > div div:nth-child(3) {
  animation-duration: 1.5151515151515151s;
}
.ldio-kzcuxtvqtwp > div div:nth-child(3) > div {
  background: #17955b;
  transform: rotate(0deg);
}
.ldio-kzcuxtvqtwp > div div:nth-child(4) {
  animation-duration: 3.0303030303030303s;
}
.ldio-kzcuxtvqtwp > div div:nth-child(4) > div {
  background: #21c271;
  transform: rotate(0deg);
}
.loadingio-spinner-wedges-9garct92ed9 {
  width: 100%;
  height: 100vh;
  display: inline-block;
  overflow: hidden;
  background: #f1f2f3;
  text-align: center;
  position: relative;
  background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
}
.ldio-kzcuxtvqtwp {
  width: 250px;
  height: 250px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateZ(0) scale(1) translateX(-50%) translateY(-50%);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-kzcuxtvqtwp div {
  box-sizing: content-box;
}
